<template>
  <v-app>
    <v-container fluid>
      <v-row>
        <v-col sm="12" cols="12">
          <div
            style="
              background-color: #fff;
              padding: 20px 20px;
              border-radius: 10px;
            "
          >
            <v-col sm="12" cols="12">
              <v-row>
                <v-col sm="12" cols="12" class="py-0">
                  <h2 class="mb-0" style="font-size: 25px; font-weight: 550">
                    {{ $t("welcome ") }} {{ this.nameMajor }}
                  </h2>
                  <v-snackbar
                    :color="snackbar.color"
                    v-model="snackbar.show"
                    right
                  >
                    {{ snackbar.message }}
                  </v-snackbar>
                  <v-dialog v-model="dialog" max-width="500px" persistent>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        class="float-right"
                        style="background-color: #5de99e"
                      >
                        <span style="font-size: 1em; color: #000000">
                          <i class="fas fa-user-plus">
                            <label class="ml-2 btn_create_new">{{
                              $t("create_major_generations")
                            }}</label>
                          </i>
                        </span>
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title v-if="this.majorGenId != ''">{{
                        $t("edit_major_generations")
                      }}</v-card-title>
                      <v-card-title v-else>{{
                        $t("create_major_generations")
                      }}</v-card-title>

                      <v-icon
                        class="btn_close"
                        @click="
                          dialog = false;
                          clear();
                        "
                        >close</v-icon
                      >
                      <v-divider />
                      <v-card-text
                        style="
                          height: 400x;
                          background-color: #edf1f5;
                          color: #333;
                        "
                      >
                        <v-container>
                          <v-row>
                            <v-col sm="12" cols="12" class>
                              <label class="label">{{ $t("name_en") }}</label>
                              <v-text-field
                                class="disable_alert my-3"
                                v-model="name_en"
                                placeholder="e.g Generation"
                                precision="3"
                                outlined
                                required
                              />
                              <label class="label">{{ $t("name_kh") }}</label>
                              <v-text-field
                                class="disable_alert my-3"
                                v-model="name_kh"
                                placeholder="e.g ជំនាន់"
                                precision="3"
                                outlined
                                required
                              />
                              <label class="label">{{
                                $t("start_year")
                              }}</label>

                              <v-text-field
                                v-model="start_year"
                                class="disable_alert my-3"
                                placeholder="e.g 2019"
                                precision="3"
                                outlined
                                required
                              >
                              </v-text-field>
                              <label class="label">{{ $t("value") }}</label>

                              <v-text-field
                                v-model="value"
                                class="disable_alert my-3"
                                placeholder="e.g 2"
                                precision="3"
                                outlined
                                required
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-divider />
                      <v-card-actions>
                        <v-btn
                          class="btn_cancel"
                          @click="(dialog = false), clear()"
                          >{{ $t("cancel") }}</v-btn
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                          class="btn_save_close"
                          @click.prevent="onSaveClose()"
                          >{{ $t("save_close") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>

              <div style="margin-top: 20px"></div>

              <v-row v-if="listMajorGeneration.length > 0">
                <v-col
                  sm="4"
                  cols="12"
                  v-for="(item, i) in listMajorGeneration"
                  :key="i"
                >
                  <v-card
                    color="#fccece"
                    dark
                    max-width="500"
                    class="mx-auto"
                    @click="
                      $router.push({
                        name: 'ManageSession',
                        params: {
                          id: item.id,
                          major_id: item.major_id,
                          name_en: item.name_en,
                        },
                      })
                    "
                  >
                    <v-menu bottom left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          style="float: right"
                          v-on="on"
                        >
                          <v-icon color="#000000">mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item link @click="onEditItem(item)">
                          <v-list-item-title>Edit</v-list-item-title>
                        </v-list-item>
                        <v-list-item link @click="deletemajorgen(item)">
                          <v-list-item-title>Delete</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-card-title style="color: #000000" class="text-h5">
                      {{ item.name_en }}
                    </v-card-title>

                    <v-card-subtitle style="font-weight: 400; color: #000000">
                      {{ item.start_year }}
                    </v-card-subtitle>

                    <router-link
                      style="text-decoration: none"
                      :to="
                        'major-admin/manage/generations/sessions/' +
                        item.id +
                        '&' +
                        item.major_id +
                        '&' +
                        item.name_en
                      "
                      tag="button"
                    >
                    </router-link>
                    <v-card-actions style="background-color: #fba0a0">
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-else align="center" justify="center" class="mb-5">
                <div>
                  <v-img
                    src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Fempty.png?alt=media&token=15a9dbf8-dca3-4df9-981d-f621d354e4ae"
                    class="center"
                  ></v-img>
                  <div class="text-h6 text-center">
                    There is no data for you to see yet
                  </div>
                  <div class="text-h6 text-center">
                    If you want to create new subject , just click
                  </div>
                  <div class="text-h6 text-center">
                    <v-btn disabled style="background-color: #5de99e">
                      <span style="font-size: 1em; color: #000000">
                        <i class="fas fa-user-plus">
                          <label class="ml-2 btn_create_new">{{
                            $t("create_subject")
                          }}</label>
                        </i>
                      </span>
                    </v-btn>
                    button and go ahead.
                  </div>
                </div>
              </v-row>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div>
      <div class="loading">
        <loading
          :active.sync="isLoading"
          :is-full-page="fullPage"
          :opacity="0.9"
          background-color="#dedede"
          :width="40"
          :height="40"
        >
          <div v-if="myLoading">
            <img
              width="100%"
              src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Floading.gif?alt=media&token=58553b10-7335-42a6-a0c8-8a6d55927816"
            />
          </div>
        </loading>
      </div>
    </div>
  </v-app>
</template>
<script>
import store from "@/store";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import {
  getMajorGenerationById,
  createMajorGeneration,
  updateMajorGeneration,
  deleteMajorGeneration,
  getMajorByAccessRole,
} from "@schoolbase/web-client-lib";
export default {
  props: ["fullPage", "alertMessage", "color", "alertText"],
  data() {
    return {
      search: "",
      headers: [
        { text: "No", value: "no" },
        { text: "English Name", value: "name_en" },
        { text: "Khmer Name", value: "name_kh" },
        { text: "Start Year", value: "start_year" },
      ],
      majorGenId: "",
      value: "",
      start_year: "",
      snackbar: {
        show: false,
        message: null,
        color: null,
      },
      listMajors: [],
      isLoading: false,
      myLoading: false,
      major_id: "",
      nameMajor: "",
      token: store.getters.getToken,
      listMajorGeneration: [],
      ListValue: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      dialog: false,
      name_en: "",
      name_kh: "",
      majorId: "",
      majorID: "",
      years: [],
    };
  },
  components: {
    Loading,
  },
  methods: {
    onEditItem(item) {
      this.name_en = item.name_en;
      this.name_kh = item.name_kh;
      this.majorGenId = item.id;
      this.start_year = JSON.parse(item.start_year);
      this.major_id = item.majorid;
      this.value = item.value;
      this.dialog = true;
    },
    // Clear Data After User click cancel or dialoge is false
    clear() {
      this.name_en = "";
      this.name_kh = "";
      this.id = "";
      this.majorGenId = "";
      this.start_year = "";
      this.value = "";
      this.major_id = "";
    },

    //Create User Function
    genearate() {
      let max = new Date().getFullYear();
      for (let i = max; i > max - 15; i--) {
        this.years.push(i);
      }

      return this.years;
    },
    async onSaveClose() {
      let data = {
        name_kh: this.name_kh,
        name_en: this.name_en,
        major_id: this.majorid,
        start_year: this.start_year,
        value: this.value,
      };
      if (this.majorGenId) {
        if (this.majorGenId !== "") {
          try {
            await updateMajorGeneration(this.majorGenId, data);
            this.loadGen();
          } catch (e) {
            this.snackbar = {
              message: e,
              color: "error",
              show: true,
            };
          }
        }
      } else {
        try {
          await createMajorGeneration(data);
          this.loadGen();
        } catch (e) {
          this.snackbar = {
            message: e,
            color: "error",
            show: true,
          };
        }
      }
      this.clear();
      this.dialog = false;
    },

    async loadGen() {
      try {
        this.isLoading = true;
        this.myLoading = true;
        let loggedUser = store.getters.getLoggedUser;
        let role = loggedUser.payload.roles[0].value;
        const Major = await getMajorByAccessRole(role, this.token);
        const listMajors = Major.payload;
        if (Major.token) {
          this.$store.commit("LOGGED_TOKEN", Major.token);
        }
        this.majorid = listMajors.id;
        this.nameMajor = loggedUser.payload.roles[0].name_en;
        this.$store.commit("LOGGED_MAJOR", Major);
        const APIResponse = await getMajorGenerationById(
          this.majorid,
          this.token
        );
        this.listMajorGeneration = APIResponse.payload.sort(function (a, b) {
          var c = new Date(a.start_year);
          var d = new Date(b.start_year);
          return c - d;
        });
        if (APIResponse.token) {
          this.$store.commit("LOGGED_TOKEN", APIResponse.token);
        }

        this.isLoading = false;
        this.myLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.myLoading = false;

        // Logout user when unauthorized call
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
    async deletemajorgen(majorGen) {
      const index = this.listMajorGeneration.indexOf(majorGen);
      this.deletItems = majorGen;
      const btnCancel = confirm("Are you sure you want to delete this item?");
      if (btnCancel === true) {
        try {
          await this.deleteMajorGeneration();
          this.listMajorGeneration.splice(index, 1);
        } catch (error) {
          this.snackbar = {
            message: error,
            color: "error",
            show: true,
          };
        }
      }
    },
    async deleteMajorGeneration() {
      try {
        await deleteMajorGeneration(this.deletItems.id);
      } catch (e) {
        throw e;
      }
    },
  },

  async mounted() {
    this.genearate();
    this.loadGen();
  },
};
</script>
<style scoped>
.v-data-table thead th {
  font-size: 16px !important;
  text-align: center !important;
}
.v-data-table tbody {
  text-align: center !important;
}

.btn_edit {
  background-color: #4e6470 !important;
  color: #fff !important;
  height: 35px;
}

.v-card__actions .v-btn.v-btn {
  padding: 0 16px;
}

.btn_create_new {
  color: #000000 !important;
  text-transform: capitalize !important;
  height: 40px !important;
  border: none !important;
  font-family: "Poppins", serif;
  font-weight: 600;
  font-size: 14px;
}

.tab_wrapper {
  position: relative;
  display: inherit;
}

.v-tab {
  min-width: 30px;
  font-size: 15px;
  text-transform: capitalize;
}

.v-icon--left {
  margin-right: 0px;
}

@media (max-width: 576px) {
}
</style>
